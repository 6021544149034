'use client'
import { AUTH_CALLBACK_URL, BASE_URL, scopes } from '@/lib/utils'
import { createClient } from '@/utils/supabase/client'
import { Auth } from '@supabase/auth-ui-react'
import { ThemeSupa } from '@supabase/auth-ui-shared'

export const AuthUI = () => {
  const supabase = createClient()
  console.log('base url', BASE_URL)

  return (
    <Auth
      supabaseClient={supabase}
      appearance={{ theme: ThemeSupa }}
      providers={['google']}
      queryParams={{
        access_type: 'offline',
        prompt: 'consent',
        // hd: 'domain.com',
      }}
      providerScopes={{
        google: scopes,
      }}
      onlyThirdPartyProviders={true}
      redirectTo={AUTH_CALLBACK_URL}
    />
  )
}
